/* Modal */
.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background-color: #fff;
    padding: 24px 32px;
    border-radius: 12px;
    max-width: 900px;
    width: 90%;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease;
    font-family: 'Segoe UI', sans-serif;
    position: relative;
}

.modalBody {
    display: flex;
    flex-direction: row;
    gap: 24px;
    height: 80vh;
    overflow-y: auto;
}

/* Физ. или юр.лицо */
.sellerInfo {
    background-color: #f8f9fc;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 16px;
    font-size: 14px;
    color: #333;
}

.sellerDescription {
    margin-top: 6px;
    font-size: 13px;
    color: #555;
    line-height: 1.5;
}

.mediaWrapper {
    position: relative;
    width: 400px;
    height: 400px;
    background: #f2f2f2;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Отзывы */
.reviewForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    border: 1px solid #e4e7ec;
}

.reviewForm label {
    font-size: 15px;
    font-weight: 600;
    color: #111349;
}

.textarea {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 10px 12px;
    font-size: 14px;
    resize: vertical;
    min-height: 90px;
    font-family: inherit;
    transition: border-color 0.2s ease;
}

.submitReviewBtn {
    align-self: flex-end;
    background-color: #111349;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submitReviewBtn:hover {
    background-color: #2a2e85;
}

.reviewsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
}

.reviewItem {
    background-color: #f2f4f7;
    padding: 12px;
    border-radius: 8px;
    border-left: 4px solid #4a90e2;
}

.reviewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}

.authorInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.reviewAuthor {
    font-weight: 600;
    font-size: 14px;
    color: #111349;
}

.country {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #555;
    gap: 4px;
}

.flag {
    width: 20px;
    height: 14px;
    border-radius: 2px;
    object-fit: cover;
}

.reviewTime {
    font-size: 12px;
    color: #888;
}

.reviewStars {
    font-size: 16px;
    color: #f5a623;
    margin-bottom: 4px;
}

.reviewText {
    font-size: 14px;
    color: #444;
}

/* END Отзывы */

.mediaItem {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.modalInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modalInfo h2 {
    margin: 0 0 10px 0;
}

.modalInfo p {
    margin: 0 0 5px 0;
}

.modalButtons {
    display: flex;
    gap: 12px;
}

.modalClose {
    padding: 10px 18px;
    background-color: #111349;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.modalClose:hover {
    background-color: #0d0f3b;
}

/* Рейтинг */
.sellerRating {
    margin-top: 16px;
    padding: 10px 14px;
    background-color: #f6f8fa;
    border-radius: 8px;
    border-left: 4px solid #4a90e2;
    font-size: 14px;
}

.stars {
    font-size: 20px;
    color: #f5a623;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
}

.ratingValue {
    color: #333;
    font-weight: bold;
    font-size: 14px;
}

.ratingVotes {
    font-size: 13px;
    color: #777;
    margin-top: 4px;
}

.quantityWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 16px 0 0 0;
}

.quantityBtn {
    background-color: #eee;
    border: none;
    padding: 6px 12px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.2s;
}

.quantityBtn:hover {
    background-color: #ddd;
}

.quantityDisplay {
    font-size: 18px;
    font-weight: 600;
    width: 32px;
    text-align: center;
}

/* Самовывоз */
.selfPickupCheckbox {
    margin-top: 12px;
    background-color: #f9fafb;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.selfPickupCheckbox label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.selfPickupCheckbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: #111349;
    cursor: pointer;
}

.selfPickupNote {
    font-size: 13px;
    color: #777;
    margin-top: 6px;
}

.addressInput {
    padding: 10px 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 16px;
    font-size: 15px;
    outline: none;
    transition: border 0.2s;
    width: 92%;
}

.addressInput:focus {
    border-color: #007bff;
}

.promoCheckbox {
    font-size: 14px;
    color: #333;
}

.promoCheckbox input[type="checkbox"] {
    margin-right: 8px;
    accent-color: #111349;
    /* тёмно-синий, как в остальном UI */
    transform: scale(1.1);
    /* немного увеличим чекбокс */
}

.promoCheckbox label {
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-10px);
    }

    40%,
    80% {
        transform: translateX(10px);
    }
}

.addressInputError {
    animation: shake 0.5s linear;
    border-color: #ff6347;
}

.sliderBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 8px 14px;
    font-size: 24px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    transition: background 0.2s;
}

.sliderBtn:hover {
    background: rgba(0, 0, 0, 0.7);
}

.left {
    left: 12px;
}

.right {
    right: 12px;
}

.warningBlock {
    border: 2px solid #ffa726;
    background-color: #fff8e1;
    padding: 16px 20px;
    border-radius: 10px;
    margin-top: 24px;
    font-size: 14px;
    color: #5d4037;
    line-height: 1.6;
}

.warningBlock h4 {
    margin: 0 0 10px;
    font-size: 16px;
    color: #bf360c;
}

.warningBlock ul {
    margin: 10px 0 10px 20px;
    padding-left: 0;
    list-style-type: disc;
}

.warningBlock code {
    background: #ffe0b2;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 13px;
}

/* Анимация ящика */
.flyBox {
    position: fixed;
    z-index: 9999;
    font-size: 28px;
    pointer-events: none;
    transition: transform 1s ease-in-out, opacity 1s;
    will-change: transform, opacity;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .modalBody {
        flex-direction: column;
        overflow-y: auto;
    }

    .mediaWrapper {
        min-height: 200px;
        width: 100%;
    }
}