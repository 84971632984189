.wrapper {
    margin-top: 32px;
    text-align: center;
}

.buttonRow {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.toggleBtn {
    background-color: #111349;
    color: white;
    padding: 14px 28px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.2s ease;
}

.toggleBtn:hover {
    background-color: #1a1d66;
}

.inviteBtn {
    background-color: white;
    color: #111349;
    padding: 14px 28px;
    border: 2px solid #111349;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.inviteBtn:hover {
    background-color: #f2f2f2;
}

.list {
    margin-top: 20px;
    overflow: visible;
}

.show {
    max-height: none;
}

.item {
    background-color: #ffffff;
    padding: 16px 20px;
    margin: 10px auto;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
    font-size: 15px;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item strong {
    color: #111349;
}

.row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
}

.subRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    font-size: 14px;
    color: #555;
    border-top: 1px dashed #eee;
    padding-top: 6px;
}

.name {
    font-weight: 600;
}

.chartWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 40px;
    padding: 0 20px;
}

.chartBlock {
    background-color: #fff;
    border-radius: 16px;
    flex: 1 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 20px;
}

.totalRow {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    color: #111349;
    padding: 0 10px;
}

.inviteModal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.fadeIn {
    transform: translateY(0%);
    opacity: 1;
}

.fadeOut {
    transform: translateY(100%);
    opacity: 0;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.modalContent {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

.modalContent input {
    width: 100%;
    padding: 12px;
    margin: 12px 0;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.inviteGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin-top: 20px;
}

.card {
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.name {
    font-weight: 600;
    font-size: 14px;
}

.stars {
    color: gold;
    font-size: 16px;
    letter-spacing: 2px;
}

.cardButton {
    margin-top: 8px;
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    background-color: #111349;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.2s ease;
}

.cardButton:hover {
    background-color: #1a1d66;
}

.cardCTA {
    background: #f9f9f9;
    border: 2px dashed #ccc;
    border-radius: 12px;
    padding: 16px;
    grid-column: span 2;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 12px;
}

.modalButtons button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.2s ease;
}

.modalButtons button:first-child {
    background-color: #111349;
    color: white;
}

.modalButtons button:last-child {
    background-color: #eee;
    color: #111;
}

@media (max-width: 640px) {
    .item {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .chartBlock {
        flex: 1 1 100%;
        overflow-x: auto;
        padding: 0px;
    }

    .chartWrapper {
        padding: 0;
    }
}