.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: relative;
}

.content {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.left {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
}

.right {
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.productInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    gap: 1rem;
}

.imageBlock img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.productDetails {
    flex: 1;
}

.productName {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 4px;
}

.priceLine {
    margin-top: 4px;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.oldPrice {
    text-decoration: line-through;
    color: #999;
    font-size: 0.85rem;
}

.newPrice {
    color: #2e7d32;
    font-size: 1rem;
    font-weight: 600;
}

.sellerInfo {
    text-align: right;
    font-size: 0.85rem;
    color: #555;
    min-width: 160px;
}

.location {
    font-size: 0.75rem;
    color: #888;
    margin-top: 2px;
}

.discountInfo {
    margin-top: 6px;
    font-size: 0.75rem;
    color: #218c4a;
    font-weight: 500;
}

.installmentList {
    margin-top: 6px;
}

.installmentLabel {
    font-size: 0.75rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 4px;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.installmentBanks {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: flex-end;
    margin-top: 2px;
}

.installmentBadge {
    background-color: #f1f1f1;
    color: #222;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    border-radius: 999px;
    border: 1px solid #ddd;
}

.sections {
    display: flex;
}

.section {
    margin-bottom: 1rem;
}

.section label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.banks {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.bankBtn {
    padding: 0.4rem 0.8rem;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 6px;
}

.bankBtn.active {
    background-color: #111349;
    color: white;
    border-color: #111349;
    font-weight: 600;
}

.bankLogo {
    height: 20px;
    width: auto;
}

.payTypes {
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
}

/* Контейнер для кастомной радио */
.radioOption {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
}

.radioInput {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #999;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: border-color 0.2s ease-in-out;
}

.radioInput:checked {
    border-color: #111349;
    background-color: #111349;
}

.radioInput:checked::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
}

.actions {
    display: flex;
    gap: 1rem;
    margin-top: auto;
}

.confirmBtn {
    background-color: #111349;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}

.cancelBtn {
    background: transparent;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 0.9rem;
}

.qrBox {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 1rem;
    background: #f7f7f7;
}

.qrBox img {
    max-width: 150px;
    height: auto;
}

.qrStatus {
    font-size: 0.8rem;
    color: #555;
    margin-top: 0.5rem;
    text-align: center;
}

.qrLabel {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
}

.successStatus {
    font-size: 0.85rem;
    color: #2e7d32;
    font-weight: 500;
    margin-top: 0.75rem;
    text-align: center;
}

.closeAfterSuccessBtn {
    background-color: #2e7d32;
    color: #fff;
    padding: 0.5rem 1.25rem;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;
    transition: background 0.2s ease-in-out;
}

.closeAfterSuccessBtn:hover {
    background-color: #256928;
}