/* Обёртка */
.wrapper {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
}

/* Заголовок */
.heading {
    font-size: 1.75rem;
    margin-bottom: 2rem;
    color: #111;
}

.sectionsRow {
    display: flex;
    flex-wrap: nowrap;
    /* или оставь wrap, если хочешь mobile stack */
    gap: 2rem;
}

/* Левая и правая колонка */
.sectionLeft {
    flex: 1 1 42%;
}

.sectionRight {
    flex: 1 1 58%;
}

/* Общие секции */
.section {
    padding: 1.5rem;
    border: 1px solid #eee;
    border-radius: 12px;
    background-color: #f9f9f9;
    position: relative;
}

/* Подзаголовок блока */
.subheading {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

/* Группа полей */
.fileUploadWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.fileInput {
    display: none;
}

.fileBtn {
    background-color: #eee;
    color: #444;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.fileBtn:hover {
    background-color: #ddd;
}

.fileName {
    font-size: 0.85rem;
    color: #777;
}


.fieldGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
}

.fieldGroup label {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 0.4rem;
}

.fieldGroup input,
.fieldGroup select {
    padding: 0.65rem 0.85rem;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    font-size: 0.95rem;
    background-color: #fdfdfd;
    color: #333;
    transition: 0.2s ease border, 0.2s ease box-shadow;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.fieldGroup input:focus,
.fieldGroup select:focus {
    border-color: #111349;
    box-shadow: 0 0 0 3px rgba(17, 19, 73, 0.1);
    outline: none;
    background-color: #fff;
}

.fieldGroup input::placeholder {
    color: #aaa;
    font-style: italic;
}

.fieldGroup input[readonly]:hover::after {
    content: "Войдите, чтобы редактировать";
    position: absolute;
    background-color: #111349;
    color: white;
    padding: 0.3rem 0.6rem;
    border-radius: 6px;
    font-size: 0.75rem;
    top: -28px;
    left: 0;
    white-space: nowrap;
    z-index: 10;
    opacity: 0.95;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.fieldHint {
    font-size: 0.8rem;
    color: #777;
    margin-top: 0.4rem;
    line-height: 1.4;
}

.fieldHint.important {
    color: #555;
    font-weight: 500;
}

/* Чтобы позиционировалось относительно родителя */
.fieldGroup {
    position: relative;
}

.photoPreview {
    margin-top: 0.75rem;
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid #ddd;
}

.deleteBtn {
    margin-top: 0.5rem;
    background-color: transparent;
    color: #c0392b;
    border: 1px solid #c0392b;
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.deleteBtn:hover {
    background-color: #c0392b;
    color: white;
}

/* Кнопка сохранения */
.clearBtn {
    margin-top: 0.5rem;
    background-color: #eee;
    color: #444;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background 0.2s ease;
}

.clearBtn:hover {
    background-color: #ddd;
}


/* 🔐 Аутентификация */
.authSection {
    position: relative;
    min-height: 240px;
    border-radius: 14px;
    overflow: hidden;
    background: linear-gradient(to bottom right, #f9f9fb, #ececf3);
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.03);
    border: 1px solid #e0e0e0;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.authSection .fieldGroup input {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 0.6rem 0.75rem;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: border 0.2s;
}

.authSection .fieldGroup input:focus {
    border-color: #111349;
    outline: none;
    background-color: #fff;
}

.mailRow {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    flex-wrap: wrap;
}

.mailInput {
    flex: 1;
    padding: 0.6rem 0.75rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 0.95rem;
    background-color: #fff;
    transition: border 0.2s;
}

.mailInput:focus {
    border-color: #111349;
    outline: none;
}

.mailBtn {
    background-color: #eee;
    color: #444;
    padding: 0.55rem 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background 0.2s ease;
    white-space: nowrap;
}

.mailBtn:hover {
    background-color: #ddd;
}

.actionsRow {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.submitBtn {
    background-color: #111349;
    color: #fff;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 6px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submitBtn:hover {
    background-color: #191e59;
}



/* Оверлей — накладывается поверх */
.lockedOverlay {
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.05);
    /* почти полностью прозрачный */
    backdrop-filter: blur(1px);
    /* минимальный, чтобы просто “снизить фокус” */
    animation: fadeInBlur 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    pointer-events: all;
}

@keyframes fadeInBlur {
    from {
        opacity: 0;
        backdrop-filter: blur(0px);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(5px);
    }
}

.lockedIcon {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
    color: #999;
}

.authBtn {
    background-color: #ccc;
    color: #333;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
}

.authBtn:hover {
    background-color: #bbb;
}

/* ✅ Адаптив: при ширине < 768px — переключаемся на вертикальное расположение */
@media screen and (max-width: 768px) {
    .sectionsRow {
        flex-direction: column;
    }

    .sectionLeft,
    .sectionRight {
        flex: 1 1 100%;
        min-width: 100%;
    }
}

.passwordBtn {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.passwordBtn:hover {
    background-color: #e5e5e5;
}